import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { Layout } from "../components/index"

const PageTemplate = ({ data }) => {
  const page = data.allWpPage.edges[0].node
  console.log(page)
  return (
    <Layout className={page.slug} pageName={page.slug} title={page.title}>
      <Seo
        title={page.title}
        description={page.excerpt}
      />
      <h1>{page.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: page.content }} />
    </Layout>
  )
}
export default PageTemplate

export const query = graphql`
 query($slug: String!) {
   site {
     siteMetadata {
       title
       siteUrl
     }
   }
   allWpPage(filter: { slug: { eq: $slug } }) {
     edges {
       node {
         title
         content
         slug
         date(formatString: "YYYY/MM/DD")
         featuredImage {
           node {
             localFile {
               childImageSharp {
                 fluid {
                   ...GatsbyImageSharpFluid_withWebp_tracedSVG
                 }
               }
             }
           }
         }
       }
     }
   }
 }
`